import type { TextProps } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';

export const RequiredAsterisk = (props: TextProps) => {
  return (
    <Text as="span" variant="danger" {...props}>
      *
    </Text>
  );
};
