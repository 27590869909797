import { Button, VStack } from '@chakra-ui/react';
import { BuildingAddressFormFields } from '@features/building/CreateBuilding/shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFCheckbox, RHFInput } from '@components/forms';
import type { Building } from '@store/services/api.generated';
import { usePatchBuildingsByBuildingIdMutation } from '@store/services/api';
import { useSetFieldErrors, useToast } from '@hooks';
import { editBuildingSchema } from '@schemas';
import type { BuildingFormFields } from '@schemas';
import { WmsModalFooter } from '@components';
import { useFlags } from 'launchdarkly-react-client-sdk';

type EditBuildingProps = {
  building: Building;
  onClose: () => void;
  onSuccess: () => void;
};

export const EditBuilding = ({ building, onClose, onSuccess }: EditBuildingProps) => {
  const { successToast, errorToast } = useToast();
  const { superAdminPrivileges } = useFlags();
  const [patchBuilding] = usePatchBuildingsByBuildingIdMutation({});

  const { control, handleSubmit, setError } = useForm<BuildingFormFields>({
    defaultValues: {
      name: building?.name ?? '',
      line1: building?.shipping_from?.line1 ?? '',
      line2: building?.shipping_from?.line2 ?? '',
      city: building?.shipping_from?.city ?? '',
      state: building?.shipping_from?.state ?? '',
      country: building?.shipping_from?.country ?? '',
      postal_code: building?.shipping_from?.postal_code ?? '',
      timezone: building?.timezone ?? '',
      insights_enabled: building?.insights_enabled ?? false,
    },
    resolver: yupResolver(editBuildingSchema),
  });
  const setFieldErrors = useSetFieldErrors({ setError });

  const onSubmit = handleSubmit(async (updatedBuilding) => {
    try {
      await patchBuilding({
        buildingId: building.id!,
        building: {
          name: updatedBuilding.name,
          shipping_from: {
            line1: updatedBuilding.line1 ?? '',
            line2: updatedBuilding.line2 ?? '',
            city: updatedBuilding?.city ?? '',
            state: updatedBuilding.state ?? '',
            country: updatedBuilding.country ?? '',
            postal_code: updatedBuilding.postal_code ?? '',
          },
          insights_enabled: updatedBuilding.insights_enabled,
          timezone: updatedBuilding.timezone,
        },
      }).unwrap();
      successToast('Building updated');
      onSuccess();
    } catch (err) {
      const didSetErrors = setFieldErrors(err);
      if (!didSetErrors) {
        errorToast('An error occurred trying to update building');
      }
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <VStack>
        <RHFInput autoFocus name="name" label="Building Name" isRequired control={control} />
        <BuildingAddressFormFields
          defaultValues={{ state: building?.shipping_from?.state, timezone: building?.timezone }}
          formControl={control}
        />
        <RHFCheckbox
          control={control}
          name="insights_enabled"
          label="Enable Insights"
          my={1}
          disabled={!superAdminPrivileges}
        />
        <WmsModalFooter width="100%">
          <Button variant="outline" colorScheme="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </WmsModalFooter>
      </VStack>
    </form>
  );
};
