import type { FifoType } from '@store/services/api.generated';
import * as yup from 'yup';
import { dateValidationSchema } from './dateValidationSchema';

export const numberOrBlankValidation = yup
  .number()
  .positive('Invalid')
  .nullable(true)
  .transform((_, val) => (isNaN(parseFloat(val)) ? null : parseFloat(val)));

const dimensionSchema = numberOrBlankValidation.when('is_shippable_in_own_container', {
  is: true,
  then: numberOrBlankValidation.required('Required when Ships in Original Packaging enabled'),
});

export const updateItemTrackingSchema = yup.object({
  lot_number: yup.string(),
  expiration_date: dateValidationSchema({ label: 'Expiration Date', key: 'expiration_date' }),
  manufacturer_date: dateValidationSchema({ label: 'Manufacturer Date', key: 'manufacturer_date' }),
  reason: yup.string(),
  notes: yup.string(),
});

export const addItemSchema = yup.object({
  quantity: yup.string().required('Must be a number'),
  reason: yup.string().required('Adjustment reason is required'),
  shipper: yup.string().required('Shipper is required'),
  productId: yup.string().required('SKU is required'),
});

export const addItemTrackingSchema = yup.object({
  expirationDate: dateValidationSchema({ label: 'Expiration Date', key: 'expirationDateTracked' }),
  manufacturerDate: dateValidationSchema({
    label: 'Manufacturer Date',
    key: 'manufacturerDateTracked',
  }),
  lotNumber: yup.string().when('$lotTracked', {
    is: true,
    then: yup.string().required('Lot Number is required'),
  }),
  expirationDateTracked: yup.bool(),
  manufacturerDateTracked: yup.bool(),
  lotTracked: yup.bool(),
});
export type CoercedFifoType = NonNullable<FifoType> | '';
export type InheritExpirationType = 'earliest' | 'latest' | '';

export const createItemSchema = yup.object({
  allow_bypass_pack_scan: yup.boolean(),
  allow_nested_packaging: yup.boolean(),
  barcodes: yup.string(),
  expiration_date_tracked: yup.boolean(),
  hazmat: yup.string(),
  height: dimensionSchema,
  inherit_expiration_from: yup.string().nullable(true),
  inherit_lot_from: yup.string().nullable(true),
  inherit_lot_from_product_id: yup.string().nullable(true),
  instructions: yup.string(),
  is_kit: yup.string(),
  is_shippable_in_own_container: yup.boolean(),
  length: dimensionSchema,
  lot_tracked: yup.boolean(),
  auto_lot_locking_enabled: yup.boolean(),
  manufacturer_date_tracked: yup.boolean(),
  name: yup.string().required('Name is required'),
  pick_strategy: yup
    .mixed<CoercedFifoType>()
    .oneOf([
      '',
      'received_at_date',
      'last_received_at_date',
      'manufacturer_date',
      'expiration_date',
      'last_expiration_date',
    ]),
  serial_number_tracked: yup.boolean(),
  shipper_id: yup.string().required('Shipper is required'),
  sku: yup.string().required('SKU is required'),
  tariff_code: yup.string(),
  unit: yup.string().required('Unit is required'),
  weight: yup.number().typeError('Invalid').required('Invalid').positive('Invalid'),
  weight_unit: yup.string().required('Weight Unit is required'),
  width: dimensionSchema,
});

export type CreateItemFormData = yup.InferType<typeof createItemSchema>;
